import React from 'react';
import { Text, Spinner } from 'wix-ui-tpa';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { classes, st } from './EmptyStatePage.st.css';
import { EmptyStatePageDataHooks } from './dataHooks';
import { TagName } from '../../../../utils/tagName.const';

export interface EmptyStatePageProps {
  isProcessing: boolean;
}

const EmptyStatePage: React.FC<EmptyStatePageProps> = ({ isProcessing }) => {
  const { isMobile, isRTL } = useEnvironment();
  const { t } = useTranslation();

  return (
    <div
      className={st(classes.root, { isMobile, isRTL })}
      data-hook={EmptyStatePageDataHooks.CONTAINER}
    >
      {isProcessing ? (
        <Spinner
          className={classes.spinner}
          data-hook={EmptyStatePageDataHooks.SPINNER}
        />
      ) : (
        <>
          <Text
            data-hook={EmptyStatePageDataHooks.TITLE}
            className={classes.title}
            tagName={TagName.MainHeading}
          >
            {t('app.empty-state-page.title')}
          </Text>
          <Text
            data-hook={EmptyStatePageDataHooks.SUBTITLE}
            className={classes.subtitle}
            tagName={TagName.Paragraph}
          >
            {t('app.empty-state-page.subtitle')}
          </Text>
        </>
      )}
    </div>
  );
};

export default EmptyStatePage;
